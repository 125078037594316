import { Button } from "components/Form/Button";
import { Layout } from "components/Layout";
import { Contributor } from "types";
import { Field } from "components/Form/Field";
import { Input } from "components/Form/Input";
import { Checkbox } from "components/Form/Checkbox";
import { Select } from "components/Form/Select";
import clsx from "clsx";
import {
  CollectionDetailsFormValue,
  useCollectionDetailsForm,
} from "./useCollectionDetailsForm";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { CollectionDetailsContext, OtherLabel } from ".";

interface CollectionDetailsProps {
  contributor: Contributor;
  onSubmit?: (data: CollectionDetailsFormValue) => void;
  setIsShow: Dispatch<SetStateAction<boolean>>;
}

export function CollectionDetailsForm(props: CollectionDetailsProps) {
  const { config } = useContext(CollectionDetailsContext);
  const { formState, addManualLabel, labels, submit } =
    useCollectionDetailsForm({ onSubmit: props.onSubmit });

  useEffect(() => {
    if (labels === null) {
      props.setIsShow(false);
    }
  }, [labels, props]);

  const watchDevice = formState.watch("device");
  const watchLabels = formState.watch("labels");
  const watchData = formState.watch("data");

  return (
    <Layout
      className="grid gap-12 items-start content-start"
      toolbar={
        <>
          <Button onClick={formState.handleSubmit(submit)}>Continue</Button>
          <Button variant="flat" onClick={() => props.setIsShow(false)}>
            Cancel
          </Button>
        </>
      }
    >
      {labels && (
        <>
          <div className="grid gap-3 text-center">
            <h1 className="text-2xl font-semibold">Add Collection Details</h1>

            <p className="text-858494">
              Enter metadata for this capture or leave blank <br /> and press
              'Continue'.
            </p>
          </div>

          <form className="grid gap-6" onSubmit={(e) => e.preventDefault()}>
            {labels.devices && labels.devices.length > 0 && (
              <Field
                label="Select applicable device"
                description={formState.formState.errors.device?.message}
                descriptionClassName={clsx({
                  "text-fd380f": formState.formState.errors.device?.message,
                })}
              >
                <Select
                  placeholder="Select label"
                  options={Object.fromEntries(
                    Object.entries(labels.devices).map(([, { key, label }]) => [
                      label,
                      key,
                    ])
                  )}
                  value={watchDevice}
                  onChange={(v) => {
                    formState.setValue("device", v as string, {
                      shouldValidate: true,
                    });
                    const camera_not_flipped = labels.devices.find(
                      (i) => i.key === v
                    )?.camera_not_flipped;

                    if (camera_not_flipped !== undefined) {
                      formState.setValue(
                        "camera_not_flipped",
                        camera_not_flipped
                      );
                    }
                    formState.clearErrors("device");
                  }}
                  renderListItem={({ value, label }) => `${value}: ${label}`}
                />
              </Field>
            )}

            {Object.entries(labels)
              .filter((i): i is [string, OtherLabel] => "title" in i[1])
              .map(([id, field]) => (
                <Field
                  key={id}
                  label={field.title}
                  description={
                    formState.formState.errors["labels"]?.[id]?.message
                  }
                  descriptionClassName={clsx({
                    "text-fd380f":
                      formState.formState.errors["labels"]?.[id]?.message,
                  })}
                >
                  <Select
                    options={field.labels}
                    value={watchLabels?.[id]}
                    withSearch={field.with_search}
                    onChange={(v) => {
                      formState.setValue(`labels.${id}`, v, {
                        shouldValidate: true,
                      });
                      formState.clearErrors(`labels.${id}`);
                    }}
                    multiple={field.multiselect}
                    renderValue={(i) => [i].flat().join(", ")}
                    renderListItem={(i) => `${i.value}: ${i.label}`}
                    listClassName="max-h-[40vh] overflow-y-auto"
                  />
                </Field>
              ))}

            {config?.show_camera_not_flipped && (
              <Checkbox
                id="camera_not_flipped"
                {...formState.register("camera_not_flipped")}
                className="!bg-white border-2 border-8a898e order-2"
                containerClassName="font-medium text-16"
                checkboxClassName="order-2"
              >
                Camera not flipped
              </Checkbox>
            )}

            {watchData?.map?.((_, i) => (
              <Field key={i} label={`${i + 1}. Additional information`}>
                <Input {...formState.register(`data.${i}`)} />
              </Field>
            ))}

            <button
              className="border-2 rounded-xl border-black/20 py-2.5 px-2 text-858494"
              onClick={addManualLabel}
            >
              Add input field
            </button>
          </form>
        </>
      )}
      {!labels && "Loading..."}
    </Layout>
  );
}
