import { Button } from "components/Form/Button";
import { Modal, ModalProps } from "components/Modal";
import { useState } from "react";
import { Agreement } from "./PrivacyModalFull/Agreement";
import { CollectionAgreement } from "./PrivacyModalFull/CollectionAgreement";

enum Screens {
  PrivacyAgreement,
  CollectionAgreement,
}

export enum PrivacyModalVariant {
  full,
  orb,
}

interface PrivacyModalProps extends ModalProps {
  onAccept?: () => void;
  onDecline?: () => void;
  textVariant?: PrivacyModalVariant;
}

export function PrivacyModal(props: PrivacyModalProps) {
  const [screen, setScreen] = useState<Screens>(Screens.PrivacyAgreement);

  return (
    <Modal show={props.show} onClose={props.onClose}>
      {screen === Screens.PrivacyAgreement && (
        <Agreement
          onClickLink={() => setScreen(Screens.CollectionAgreement)}
          buttons={
            <>
              <Button
                variant="flat"
                onClick={props.onDecline}
                className="text-858494"
              >
                Decline
              </Button>

              <Button onClick={props.onAccept}>Accept</Button>
            </>
          }
        />
      )}
      {screen === Screens.CollectionAgreement && (
        <CollectionAgreement
          onClose={() => setScreen(Screens.PrivacyAgreement)}
        />
      )}
    </Modal>
  );
}
