import { Layout } from "components/Layout";
import { memo, useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling-new";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "types";
import { Button } from "components/Form/Button";
import { decodeJwt } from "jose";
import { getUserEmail } from "lib/get-user-email";
import { usePrivacyModal } from "hooks/usePrivacyModal";

const qrCode = new QRCodeStyling({
  type: "svg",
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  cornersDotOptions: {
    type: "dot",
  },
  dotsOptions: {
    color: "var(--colors-qrcode)",
    type: "extra-rounded",
  },
  backgroundOptions: {
    color: "transparent",
  },
  imageOptions: {
    margin: 4,
    hideBackgroundDots: true,
  },
});

export const Orb = memo(function Orb() {
  const [init, setInit] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | undefined>();
  const navigate = useNavigate();
  const qrRef = useRef<HTMLDivElement>(null);
  const privacyModal = usePrivacyModal();

  // NOTE: Read and decode token
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decoded = decodeJwt(token);
        if (decoded.registered) {
          setUserId(`${decoded.sub}`);
        }
      } catch {}
    }
  }, []);

  useEffect(() => {
    if (privacyModal.accepted === null) {
      return;
    }

    if (privacyModal.accepted === false) {
      navigate(AppRoutes.home);
    }

    setInit(true);
  }, [navigate, privacyModal.accepted]);

  // NOTE: Setup qrcode
  useEffect(() => {
    if (!init) {
      return;
    }

    const QRElement = qrRef.current;

    if (!QRElement) {
      return;
    }

    if (qrRef.current) {
      qrCode.append(qrRef.current);
      qrCode.update({
        data: userId ?? "worldcoin.org",
        width: qrRef.current?.clientWidth,
        height: qrRef.current?.clientWidth,
      });
    }

    const updateSizes = () => {
      const size = qrRef.current?.clientWidth;

      if (qrCode._options.width === size) {
        return;
      }

      qrCode.update({
        width: size,
        height: size,
      });
    };

    window.addEventListener("resize", updateSizes);
    // NOTE: fix for ios safari text increase/decrease
    window.addEventListener("focus", updateSizes);

    return () => {
      window.removeEventListener("reset", updateSizes);
      window.removeEventListener("focus", updateSizes);
    };
  }, [navigate, userId, init]);

  if (!init) {
    return null;
  }

  return (
    <Layout
      toolbar={<Button to={AppRoutes.home}>Go Back</Button>}
      toolbarClassName="bg-white"
    >
      <div className="relative h-full grid grid-rows-[auto_1fr] items-center gap-12 text-center">
        <div className="space-y-6">
          <div className="grid gap-2">
            <h1 className="text-24 font-semibold">Device capture</h1>
            <div className="text-858494">
              <p>Please show this QR code to the device.</p>
            </div>
          </div>

          <div className="grid gap-1 justify-center font-medium">
            <p>Signed in as</p>

            <span className="bg-858494/10 rounded-[20px] px-3 py-2">
              {getUserEmail()}
            </span>
          </div>
        </div>

        <div ref={qrRef} className="w-[calc(min(100vw,100%)-64px)] mx-auto" />
      </div>
    </Layout>
  );
});
