import clsx from "clsx";
import { Layout } from "components/Layout";
import { CSSProperties, useEffect, useState } from "react";

export function Uploading(props: { files: Array<File>; progress: number }) {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    setImage((prev) => {
      if (prev) {
        URL.revokeObjectURL(prev);
      }

      return props.files[props.progress]
        ? URL.createObjectURL(props.files[props.progress])
        : null;
    });
  }, [props]);

  if (!image) {
    return null;
  }

  return (
    <Layout>
      <div className="absolute inset-0 backdrop-blur-sm z-10">
        <div className="absolute inset-x-6 top-1/2 -translate-y-1/2 rounded-xl bg-white p-8 z-10 space-y-8">
          <div className="text-center space-y-2">
            <p className="font-medium">Uploading image...</p>

            <p className="text-858494 text-14">
              Your image is uploading right now, please wait.
            </p>
          </div>

          <div
            className={clsx(
              "relative w-full rounded-sm bg-f1f5f8 h-1 overflow-clip",
              "before:absolute before:inset-y-0 before:left-0 before:bg-3461fd before:duration-[2s] before:w-[var(--progress)]"
            )}
            style={
              {
                "--progress": `${
                  ((props.progress + 1) / props.files.length) * 100
                }%`,
              } as CSSProperties
            }
          />
        </div>
      </div>

      <img
        className={clsx("absolute inset-0 object-cover w-full h-full")}
        src={image}
        alt=""
      />
    </Layout>
  );
}
