import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppRoutes, Contributor } from "types";
import { CollectionDetailsForm } from "./form";
import { useLocation } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { getPrismicCollectionDetails } from "lib/prismic";

export type DeviceLabel = {
  key: string;
  label: string;
  camera_not_flipped: boolean;
};
export type OtherLabel = {
  title: string;
  multiselect: boolean;
  required: boolean;
  labels: Record<string, string>;
  with_search: boolean;
};

export interface Config {
  labels?: {
    devices: Array<DeviceLabel>;
  } & Record<string, OtherLabel>;
  enable_capture: boolean;
  enable_file_uploads: boolean;
  show_collection_details: boolean;
  show_camera_not_flipped: boolean;
  capture_image_count: number;
}

export interface CollectionDetailsContextInterface {
  contributor: Contributor;
  collectionDetails: Array<string> | undefined;
  resetCollectionDetails: () => void;
  setIsShow: (value: boolean) => void;
  config: Config | null | undefined;
}

export const CollectionDetailsContext =
  createContext<CollectionDetailsContextInterface>({
    contributor: "appen",
  } as CollectionDetailsContextInterface);

export const CollectionDetailsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const location = useLocation();
  const { token } = useContext(AuthContext);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [contributor, setContributor] = useState<Contributor>("appen");
  const [config, setConfig] = useState<Config | undefined | null>();

  // NOTE: set contributor
  useEffect(() => {
    if (!token) {
      return;
    }
    const { contributor } = token;
    if (contributor) {
      setContributor(contributor);
    }
  }, [location.pathname, token]);

  // NOTE: fetch config
  useEffect(() => {
    if (!token?.contributor) {
      return;
    }

    (async () => {
      const res = await getPrismicCollectionDetails();

      if (!res) {
        return setConfig(null);
      }

      setConfig({
        labels: {
          devices: res.devices.map(({ key, label, camera_not_flipped }) => ({
            key: key!.toString(),
            label: label!.toString(),
            camera_not_flipped,
          })),
          ...Object.fromEntries(
            res.simple_labels
              .filter(
                (i, idx, arr) =>
                  (i.title?.length || 0) > 0 &&
                  (i.labels?.length || 0) > 0 &&
                  arr.findIndex((j) => j.id === i.id) === idx
              )
              .map((i) => {
                return [
                  i.id,
                  {
                    title: i.title!,
                    multiselect: i.multiselect || false,
                    required: i.required || false,
                    labels: Object.fromEntries(
                      (i.labels || "")
                        ?.match(/^(.*)$\n?/gm)
                        ?.map((j) => j.trim().split(/\t+/).reverse()) || []
                    ),
                  },
                ];
              })
          ),
        },
        enable_capture: res.enable_capture,
        enable_file_uploads: res.enable_file_uploads,
        show_collection_details: res.show_collection_details,
        show_camera_not_flipped: res.show_camera_not_flipped || false,
        capture_image_count: res.capture_image_count || 1,
      });
    })();
  }, [token]);

  // NOTE: show form
  useEffect(() => {
    if (
      config &&
      [AppRoutes.capture, AppRoutes.batchUpload].includes(
        location.pathname as AppRoutes
      ) &&
      config.show_collection_details
    ) {
      setIsShow(true);
    }
  }, [config, location.pathname]);

  const [collectionDetails, setCollectionDetails] = useState<
    Array<string> | undefined
  >(undefined);

  const resetCollectionDetails = useCallback(
    () => setCollectionDetails(undefined),
    []
  );

  return (
    <CollectionDetailsContext.Provider
      value={{
        contributor,
        collectionDetails,
        resetCollectionDetails,
        setIsShow,
        config,
      }}
    >
      {isShow && (
        <CollectionDetailsForm
          onSubmit={(data) => {
            setIsShow(false);
            setCollectionDetails(data as unknown as any);
          }}
          setIsShow={setIsShow}
          contributor={contributor}
        />
      )}
      {!isShow && children}
    </CollectionDetailsContext.Provider>
  );
};
