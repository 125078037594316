import {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout } from "components/Layout";
import { Button } from "components/Form/Button";
import { UploadContext } from "contexts/UploadContext";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "types";
import { GalleryPreview } from "components/GalleryPreview";
import { Explanation, ExplanationIcons } from "components/Explanation";
import { usePrivacyModal } from "hooks/usePrivacyModal";
import { CollectionDetailsContext } from "contexts/CollectionDetails";

export const Capture = memo(function Capture() {
  const [title, setTitle] = useState<string>("");
  const [init, setInit] = useState<boolean>(false);

  const {
    files: images,
    addFile: addImage,
    resetFiles: resetImages,
    upload,
  } = useContext(UploadContext);
  const privacyModal = usePrivacyModal();
  const { setIsShow, config } = useContext(CollectionDetailsContext);

  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      const file = e.target.files?.[0];
      // NOTE: resets input state, if we don't do this, this event never fires again
      e.target.value = "";
      addImage(file);
    },
    [addImage]
  );

  const captureButtonLabel = useMemo(() => {
    if (!config) {
      return;
    }

    if (images.length === 0) {
      return "Start capture";
    }

    if (images.length > 0 && images.length <= config.capture_image_count - 1) {
      return "Continue capturing";
    }

    return "Submit";
  }, [images.length, config]);

  const handleCancel = useCallback(() => {
    resetImages();
    navigate(AppRoutes.home, { replace: true });
  }, [navigate, resetImages]);

  useEffect(() => {
    if (!config) {
      return;
    }

    if (images.length <= 0) {
      return setTitle("Face capture");
    }

    const imagesLeft = config.capture_image_count - images.length;

    if (imagesLeft > 0) {
      let selfie = "selfie";

      if (imagesLeft > 1) {
        selfie = "selfies";
      }

      setTitle(
        `Take ${config.capture_image_count - images.length} more ${selfie}`
      );
    } else {
      setTitle("Preview images");
    }
  }, [images, config]);

  useEffect(() => {
    if (!config || privacyModal.accepted === null) {
      return;
    }

    if (
      privacyModal.accepted === false ||
      (config?.enable_capture || false) === false
    ) {
      return navigate(AppRoutes.home);
    }

    setInit(true);
  }, [config, navigate, privacyModal.accepted]);

  if (!init || !config) {
    return null;
  }

  return (
    <Layout
      className="space-y-8"
      toolbarClassName="z-[100]"
      toolbar={
        <>
          {images.length < config.capture_image_count && (
            <label className="bg-3461fd text-white hover:bg-3461fd/80 text-center p-4.5 rounded-xl cursor-pointer">
              <input
                ref={inputRef}
                type="file"
                id="user"
                capture="user"
                accept="image/jpeg,image/png,image/webp,video/mp4,video/quicktime"
                className="hidden"
                onChange={handleChange}
              />
              {captureButtonLabel}
            </label>
          )}
          {images.length >= config.capture_image_count && (
            <Button onClick={upload}>Submit</Button>
          )}
          {images.length < config.capture_image_count && (
            <Button variant="flat" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {images.length >= config.capture_image_count && (
            <Button
              variant="flat"
              onClick={() => {
                resetImages();
                setIsShow(true);
              }}
            >
              Change selection
            </Button>
          )}
        </>
      }
    >
      <h1 className="text-24 font-semibold text-center">{title}</h1>

      {images.length > 0 && (
        <div className="text-858494 leading-5 text-center">
          Review images, videos, and labels carefully. <br />
          Correct any mismatches before uploading.
        </div>
      )}

      {images.length <= 0 && (
        <Explanation
          items={[
            {
              icon: ExplanationIcons.selfie,
              title: `Take ${config.capture_image_count} selfie(s)`,
              description: (
                <p>
                  Tap on the capture button to save the picture. Tap retake to
                  start over.
                </p>
              ),
            },
            {
              icon: ExplanationIcons.bulb,
              title: "Recommendations",
              description: (
                <ul className="leading-[18px] list-disc">
                  <li>Make sure there’s some lighting around.</li>
                  <li>Use a neutral expression.</li>
                  <li>
                    Take the picture so your shoulders and above are visible.
                  </li>
                </ul>
              ),
            },
          ]}
        />
      )}
      {images.length > 0 && (
        <GalleryPreview
          min={config.capture_image_count}
          showLabels={
            images.length >= config.capture_image_count &&
            config?.show_collection_details
          }
          isRemovable
        />
      )}
    </Layout>
  );
});
