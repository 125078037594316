import { memo, useCallback, useContext, useMemo } from "react";
import { Button } from "components/Form/Button";
import { Layout } from "components/Layout";
import { AppRoutes } from "types";
import { toast } from "react-toastify";
import { ReactComponent as Copy } from "assets/copy.svg";
import { ReactComponent as SuccessIcon } from "assets/success.svg";
import { UploadContext } from "contexts/UploadContext/index";

export const Success = memo(function Success() {
  const context = useContext(UploadContext);

  const captureId = useMemo(() => context.captureId, [context.captureId]);

  const copyCaptureId = useCallback(() => {
    if (!captureId) {
      return;
    }

    navigator.clipboard.writeText(captureId).then(() => {
      toast.success("CaptureId copied to clipboard");
    });
  }, [captureId]);

  return (
    <Layout
      className="grid grid-rows-[auto_1fr]"
      toolbar={<Button to={AppRoutes.home}>Take me home</Button>}
    >
      {captureId && (
        <div className="flex flex-col flex-nowrap whitespace-nowrap items-start justify-stretch w-full gap-3">
          <p className="font-medium">Capture ID</p>
          <div className="bg-f4f7ff rounded-xl py-2.5 px-3.5 flex justify-between items-center w-full">
            <p className="text-sm">{captureId}</p>
            <Copy className="ml-1.5" onClick={copyCaptureId} />
          </div>
        </div>
      )}

      <div className="flex flex-col self-center justify-self-center gap-8">
        <SuccessIcon />

        <div className="grid gap-11">
          <div className="text-center space-y-2">
            <h3 className="font-medium">Upload Successfull</h3>

            <p className="text-14 text-858494 leading-1.5">
              Thank you. Your data has been <br />
              successfully uploaded.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
});
