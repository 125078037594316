import { Button } from "components/Form/Button";
import { ReactNode } from "react";

export function Agreement(props: {
  onClickLink: () => void;
  buttons: ReactNode;
}) {
  return (
    <div className="grid gap-6">
      <div className="grid gap-2 text-center">
        <h3 className="font-medium">Privacy Agreement</h3>
        <div>
          We ask you for your consent to collect your iris and face images to
          train our algorithms with that data. We also ask you for consent to
          transfer that data under appropriate safeguards to the European Union
          and the USA. Please read and accept the&nbsp;
          <Button
            onClick={props.onClickLink}
            variant="link"
            className="inline text-3461fd hover:opacity-75 !px-0 !py-0"
          >
            data collection agreement
          </Button>
          &nbsp; to continue.
        </div>
      </div>
      <div className="flex gap-2 [&>button]:w-full [&>button]:text-14 [&>button]:py-3">
        {props.buttons}
      </div>
    </div>
  );
}
